@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;400;500;600;800&display=swap');


$bg-purple-light: #f5f5ff;
$bg-purple-dark: #BFBEFF;
$neutral-gray: #3B3B3B;
$neutral-black: #141417;
$green-text: #298C69;
$gray-text: #8B8B8B;

$comuna-green: #D7FFB8;
$comuna-dark-green: #345412;
$comuna-orange: #F78F17;

$comuna-lime: #DEF2B1;
$comuna-lime-dark: #F7FCEC;

$comuna-purple: #615DFF;
$comuna-purple-light: #7E7BFF;
$comuna-purple-bg: rgba(97, 93, 255, 0.2);
$comuna-purple-light-bg: #E6E5FF;
$comuna-purple-light-alpha: #F5F5FF;

$comuna-red: #F02940;
$comuna-red-light: #FDE7EA;
$comuna-red-dark: #5F0711;
$comuna-clouds: #BAB9FF;
$comuna-transparent: rgba(1, 1, 1, 0);
$neutral-gray: #3B3B3B;

$comuna-blue-light: #EDF5FD;
$comuna-blue-text: #0C325A;

$comuna-yellow-light: #FFFEE5;
$comuna-yellow-text: #5C5600;

$comuna-green-light: #EFFAF5;
$comuna-dark-green-text: #07754D;

$comuna-purple-tab-light: #F9F6FF;
$comuna-purple-text: #390080;

$comuna-orange-lighter: #FFF7EB;
$comuna-orange-light: #FFEDD1;
$comuna-orange-text: #8F5B00;
$comuna-orange-dark-text: #5C3B00;
$comuna-orange-dark: #CC8200;

$comuna-pink: #FCCFD5;


$calculator: #F4F2EC;

$green: #13AD13;
$ligth-green: #E8FCE8;
$bg-light-green: #E8FCE8;
$bg-light-orange: #FFEDD1;;

$black: #222222;

$gray-0: #EFEFEE;
$gray-1: #F5F5F4;
$gray-2: #E6E8EF;
$gray-3: #DBDEE8;
$gray-4: #B8B8C2;
$gray-45: #7C8B9D;
$gray-5: #6E6E76;

body, html, #root{
    font-family: 'Outfit', sans-serif;
    color: $black;
    background-color: $bg-purple-light;
    overflow-y: hidden;
    height: 100%;
}

p{
    color: $gray-5
}

h2{
    font-weight: bold;
    font-size: 1.6em;
    margin-bottom: 0px
}

h3{
    font-weight: bold;
    font-size: 1.6em;
    color: $gray-45;
}
.animated{
    transition: all ease 0.2s;
}

.comuna-card{
    background-color: white;
    border-radius: 12px;
    overflow: hidden;
}

.comuna-sub-card {
    background-color: $gray-1;
    border-radius: 10px;
    overflow: hidden;
}

.comuna-sidebar{
    background-color: white;
    width: 364px;
    height: 100%;
    min-width: 284px;
}

.comuna-side-panel{
    height: 100%;
    width: 100%;
    max-width: 1024px;
    background-color: white;
    position: absolute;
    bottom: 0px;
    right: 0px;
    overflow-y: auto;
    overflow-x: visible;
    display: flex;
    flex-direction: column;

    &.bottom{
        height: fit-content;
        flex-direction: row;
    }
    
    &.open{
        animation: slide-right 0.39s ease-out forwards;
    }

    &.close{
        animation: slide-right 0.22s ease-out reverse forwards;
        /* on close state we wish to remain on the last animated state. */
    }
}

.cover{
    background-color: rgba(0,0,0,0.5);
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
}

.comuna-sub-card-light {
    background-color: $comuna-purple-light-alpha;
    border-radius: 10px;
    overflow: hidden;
}

.comuna-step-indicator{
    background-color: #DBDBDB;
    border-radius: 12px;
    height: 8px;
    width: 100%;
    &.completed{
        background-color: #47B888;
    }
}

.comuna-field{
    background-color: $bg-purple-light;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1rem;
    border-radius: 0.75rem;
    margin-bottom: 1rem;
    color: $neutral-black;
    width: 100%;
}

.comuna-feature{
    max-width: 280px;
    border: 1px solid $gray-2;
    border-radius: 12px;
    padding: 17px;
    margin: 10px;
    padding-bottom: 64px;

    h2{
        font-size: 1.2em;
        line-height: 1em;
        margin-top: 17px;
        margin-bottom: 17px;
    }
}

.comuna-input{
    background-color: white;
    border: solid 2px $gray-2;
    border-radius: 12px;
    margin-top: 3px;
    padding: 12px;
    width: 100%;

    &:disabled{
        background-color: hsl(0, 0%, 95%);
    }
}

.comuna-input-italic {
    border: solid 2px $gray-2;
    border-radius: 12px;
    margin-top: 3px;
    padding: 12px;
    width: 100%;
    font-style: italic;
    font-size: 30px;
}

.comuna-error {
    border: solid 2px red !important;
    border-radius: 12px;
    margin-top: 3px;
    padding: 12px;
    width: 100%;
}

.comuna-input-disabled{
    background-color: $gray-1;
    border: solid 2px $gray-1;
    border-radius: 12px;
    margin-top: 3px;
    padding: 12px;
    width: 100%;
}

.comuna-select
{
    color: black;
}

.on-front{
    z-index: 1000;
    position: relative;
}

.on-hover{
    background-color: rgba(0,0,0,0.3);
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: all 0.1s ease;

    &:hover{
        opacity: 1;
    }

}


.comuna-select>div, .comuna-select-error>div{
    border: solid 2px $gray-2;
    border-radius: 12px;
    margin-top: 3px;
    padding: 4px;
    width: 100%;
    height: 52px;
    text-align: left;
}

.comuna-select-error>div{
    border: solid 2px red;
}

.comuna-input.comuna-selected{
    background-color: $comuna-purple-light-bg;
    border: solid 2px $comuna-purple;
}

.comuna-input.comuna-selected-plain {
    background-color: $comuna-clouds;
    // border: solid 2px $comuna-purple;
}

.comuna-input:focus{
    border: solid 2px $comuna-purple;
    box-shadow: 0px 0px 0px 4px $comuna-purple-bg;
}

.comuna-input:-webkit-autofill
{
    background-color: white!important;
}

.comuna-input-label{
    display: block;
    color: $gray-5;
    font-size: 0.875em;
    margin-top: 12px;
    margin-bottom: 4px;

    &.red{
        color: red;
    }
}

.comuna-link{
    color: $comuna-purple;
    font-weight: bold;
}

.comuna-link:hover{
    text-decoration: underline;
}

.comuna-link-small {
    color: $comuna-purple;
    font-weight: normal;
}

.comuna-link-small:hover {
    text-decoration: underline;
}

.comuna-popup{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: rgba(0,0,0,0.5);
    z-index: 10000;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-top: 50px;
    padding-bottom: 50px;
    overflow-y: auto;
    overflow-x: hidden;
    backdrop-filter: blur(4px);

    &.open{
        animation: background-fade 0.3s forwards;
    }

    &.close{
        animation: background-fade 0.2s reverse forwards;
    }

    &.fixed{
        position: fixed;
        top: 0px;
        left: 0px;
    }
}

@mixin comuna-button {
    color: white;
    font-weight: medium;
    border-radius: 24px;
    flex-direction: row;
    font-size: 1em;
    padding: 12px;
    width: 100%;
    text-align: center;
    position:relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 49px;

    &.sm{
        width: fit-content;
        padding: 12px 42px;
    }

    &.xs{
        width: fit-content;
        padding: 12px 24px;
    }

    &.xss {
        width: fit-content;
        padding: 3px 10px;
        height: min-content;
        font-size: 0.8em;
    }

    &.icon img{
        width: 24px;
        height: 24px;
        margin-right: 8px;
    }

    &.center{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.border-bottom-purple{
    border-bottom: 4px solid $comuna-purple;
}

.comuna-bg-stripes{
    background-image: url("./img/bg_stripes_gray.png");
    background-size: 1920px;
}

/* DUPLICAT CLASS
.comuna-circle-button{
    width: 44px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    background-color: $comuna-purple-light-bg;
    margin-left:14px;
    img{
        width: 24px;
        height: 24px;
    }
    &:hover{
        background-color: $bg-purple-dark;
    }
}
*/

.comuna-action-button{
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 12px;
    font-weight: bold;
    padding: 18px;
    width: 100%;
    margin-bottom: 10px;
    background-color: white;
    transition: all ease 0.2s;

    img{
        width: 24px;
        height: 24px;
        margin-right: 12px;
    }

    &:hover{
        background-color: $bg-purple-light;
    }
}

.comuna-primary-button{
    @include comuna-button;
    background-color: $comuna-purple;
}

.comuna-special-button{
    @include comuna-button;
    background-color: $comuna-orange;
}

.comuna-mint-button{
    @include comuna-button;
    background-color: $comuna-green;
    color: $comuna-purple;
}

.comuna-secondary-button{
    @include comuna-button;
    background-color: $comuna-purple-light-bg;
    color: $comuna-purple;
    font-weight: medium;
    font-size: 1em;
    padding: 12px;
    border-radius: 24px;
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 49px;

    &.sm{
        width: fit-content;
        padding: 12px 42px;
    }

    &.xs{
        width: fit-content;
        padding: 12px 8px;
        height: 32px;
    }

    &:hover{
        background-color: $bg-purple-dark;
    }

   
}

.comuna-white-button {
    @include comuna-button;
    background-color: $comuna-purple-light-bg;
    color: $comuna-purple;
    font-weight: medium;
    padding: 12px;
    border-radius: 24px;
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 49px;

    &.sm {
        width: fit-content;
        padding: 12px 42px;
    }

    &.xs {
        width: fit-content;
        padding: 12px 8px;
        height: 32px;
    }

    &:hover {
        background-color: $bg-purple-dark;
    }

}

.comuna-transparent-button {
    @include comuna-button;
    background-color: #fff;
    color: #141417;
    font-weight: medium;
    font-size: 1em;
    padding: 12px;
    border-radius: 24px;
    border-width: 3px;
    border-color: #dedee1;
    width: 100%;
    text-align: center;
    
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 49px;

    &.sm {
        width: fit-content;
        padding: 12px 42px;
    }

    &.xs {
        width: fit-content;
        padding: 12px 8px;
        height: 32px;
    }

    &:hover {
        background-color: #dedee1;
    }


}

.comuna-third-button{
    @include comuna-button;
    background-color: $comuna-red;
}

.comuna-orange-light-button {
    @include comuna-button;
    background-color: $comuna-orange-light;
    color: $comuna-orange-text;
}

.comuna-orange-dark-button {
    @include comuna-button;
    background-color: $comuna-orange-dark;
    color: white;
}

.comuna-delete-button{
    @include comuna-button;
    background-color: $comuna-red;
    color: white;
}

.comuna-pink-button {
    @include comuna-button;
    background-color: $comuna-red-light;
}

.comuna-fourth-button {
    @include comuna-button;
    background-color: white;
    border-width: 2px;
    border-color: $comuna-red;
    color: $comuna-red;
}

.comuna-disabled-button{
    @include comuna-button;
    background-color: $gray-4;
}

.comuna-circle-button{
    position:relative;
    border-radius: 50%;
    height: 56px;
    width: 56px;
    display: flex;
    justify-content: center;
    align-items: center;

    span{
        position: absolute;
        left: 80%;
        top: 50%;
        transform: translate(0%, -50%);
        padding: 14px 10px;
        border-radius: 12px;
        transition: all ease 0.2s;
        opacity: 0;
        z-index: 100;
    }

    &.selected{
        background-color: $bg-purple-dark;
     }

    &:hover{
        background-color: $comuna-purple-light;
    }

    &:hover span{
        opacity: 1;
        left: 110%;
    }

    &.alt-background{
        background-color: $comuna-purple;
    }

    img{
        width: 24px;
        height: 24px;
    }
}

.comuna-circle-button-alt {
    @extend .comuna-circle-button;
    background-color: $comuna-purple-light-bg;

    &.selected {
        background-color: $bg-purple-dark;
    }

    &:hover {
        background-color: $bg-purple-dark;
    }
}

.cancel-button{
    border-color: $comuna-red!important;
    color: $comuna-red!important;
}

.comuna-select-clear {
    height: 20px;
    width: 20px;
    position: absolute;
    top: 10px;
    right: 4px;
    font-size: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.comuna-circle-number{
    height: 20px;
    width: 20px;
    position: absolute;
    background-color: $comuna-purple-light-bg;
    top: 10px;
    right: 130px;
    font-size: 12px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
}

.comuna-tab{
    color: $gray-5;
    padding-bottom: 8px;
    font-weight: bold;
    border-bottom: 4px solid #fff;
}

.comuna-tab-selected{
    color: $black;
    padding-bottom: 8px;
    font-weight: bold;
    border-bottom: 4px solid $comuna-purple;
}

.comuna-small-icon{
    width: 32px;
    height: 32px;
    background-color: $comuna-purple-bg;
    border-radius: 100%;
    padding:8px;
}

.comuna-small-picture{
    width: 56px;
    height: 56px;
    border-radius: 16px;
    &.rounded-full{
        border-radius: 50%;
    }
}

.comuna-small-table{
    text-align: left;
    width: 100%;

    table{
        width: 100%;
    }

    td{
        padding: 10px 5px;
    }

    tr:first-child{
        border-bottom: 2px solid #E6E8EF;
        font-weight: medium;
        color: $gray-5;
        width: 100%;

        td{
            padding-bottom: 10px;
        }
    }
}

.comuna-small-table td:last-child{
    text-align: right;
}

.muna-icon{
    width: 42px;
    height: 42px;
    background-color: $comuna-purple-bg;
    border-radius: 100%;
    padding: 10px;

    &.sm{
        width: 36px;
        height: 36px;
    }

    &.xl {
        width: 120px;
        height: 120px;
        padding: 24px;
    }
}

.muna-icon-big-white {
    width: 52px;
    height: 52px;
    background-color: white;
    border-radius: 100%;
    padding: 8px;

    &.sm {
        width: 36px;
        height: 36px;
    }

    &.xl {
        width: 120px;
        height: 120px;
        padding: 24px;
    }
}

.muna-icon-transparent {
    width: 42px;
    height: 42px;
    background-color: $comuna-transparent;
    border-radius: 100%;
    padding: 8px;

    &.sm {
        width: 36px;
        height: 36px;
    }

    &.xl {
        width: 120px;
        height: 120px;
        padding: 24px;
    }
}

.chevron-right{
    width: 24px;
    height: 27px;
    transform: scale(-1, 1);
}

.comuna-big-icon{
    width: 130px;
    height: 130px;
    background-color: $comuna-purple;
    border-radius: 100%;
    padding:30px;
}

.muna-logo{
    width: auto;
    height: 38px;
    margin-right: 42px;
    object-fit: contain;
}

.muna-logo-big {
    width: auto;
    height: 76px;
    margin-right: 42px;
    object-fit: contain;
}

.comuna-top-bar{
    font-size: 1.1em;
    background-color: white;
    color: $gray-5;
    padding: 16px;
    display: flex;
    justify-content: space-between;
    font-weight: 500;
    align-items: center;
    border-bottom: 2px solid $gray-2;
    padding-left: 48px;
    z-index: 100;

    img{
        width: auto;
        height: 38px;
        margin-right: 42px;
    }

    a{
        margin-right: 1.5em;
    }

    .selected{
        color: $comuna-purple;
    }

    &.transparent{
        color: white;
        background-color: rgba(255,255,255,0);
        .selected{
            color: $comuna-green;
        }
    }
}

.comuna-column{
    background-color: white;
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    margin: 0px 8px;
    min-width: 296px;
    padding: 12px 16px 16px 12px;
    border-radius: 12px;
    border: 2px solid white;
    overflow-y: auto;
    position: relative;

    @media (max-height: 1000px) {
        max-height: 820px;
    }

    .count{
        position: absolute;
        top: 10px;
        right: 10px;
        padding: 3px 12px;
        border-radius: 12px;
        font-weight: bold;
    }
}

.comuna-column-interactive:hover{
    border: 2px solid $comuna-purple;
}

.comuna-contract{
    margin: auto;
    color: black;
    max-width: 55em;

    h1{
        font-size: 2em;
        font-weight: bold;
        width: 100%;
        text-align: center;
        margin-bottom: 1em;
    }

    p, li, H2, H3, H4{
        width: 100%;
        max-width: 60em;
        text-align: justify;
        margin:auto;
        margin-bottom: 1em;
        color: black;
    }
}

.comuna-green{
    background-color: #E8FCE8;
    color: #13AD13;
    font-weight: 400;
}

.comuna-green-text {
    color: #D7FFB8;;
}

.comuna-orange-dark-text{
    color: $comuna-orange-dark-text;
}

.comuna-green-bold{
    background-color: #D7FFB8;
    color: #13AD13;
    font-weight: bold;
}

.comuna-orange-dark-text{
    color: $comuna-orange-dark-text;
}

.comuna-purple-bold{
    background-color: $comuna-purple-light-bg;
    color: $comuna-purple;
    font-weight: medium;
}

.comuna-green-dark-bold {
    background-color: #D7FFB8;
    color: $comuna-dark-green;
    font-weight: bold;
}

.comuna-purple-dark-bold {
    background-color: $comuna-purple-light-bg;
    color: $comuna-purple;
    font-weight: bold;
}

.comuna-purple-light-bold{
    background-color: $comuna-purple;
    color: $comuna-purple-light-bg;
    font-weight: medium;
}

.bg-purple-dark{
    background-color: $bg-purple-dark;
}

.bg-purple-light{
    background-color: $bg-purple-light;
}

.comuna-red{
    background-color: #fce8e8;
    color: #ad1313;
    font-weight: 400;
}

.comuna-red-alt{
    background-color: $comuna-red;
    color: $comuna-red-light;
    font-weight: medium;
}

.comuna-withdraw-method{
    display: flex;
    flex-direction: row;
    padding: 20px 12px;
    border-bottom: 2px solid $gray-2;

    &:last-child{
        border-bottom: none;
    }

    .icon{
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px solid $gray-2;
        font-weight: bold;
        background-color: $gray-1;
        border-radius:100%;
        height: 40px;
        width: 40px;
    }
}

.notification-button{
    border-radius: 50%;
    height: 56px;
    width: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    & .count{
        border-radius: 50%;
        height: 22px;
        width: 22px;
        text-align: center;
        color: white;
        font-weight: bold;
        background: red;
        position: absolute;
        top: -6px;
        font-size: 12px;
        right: -9px;
        border: 2px solid white;
    }

    & img{
        width: 45%!important;
        margin: auto;
        height: auto;
    }

    &.open{
        background-color: $comuna-purple-light-bg;
    }

    &.empty{
        background-color: $bg-purple-light;
    }

    &.full{
        background-color: $comuna-purple;
    }

    & .tray{
        position: absolute;
        top: 50px;
        right: 0px;
        background-color: white;
        width: 380px;
        border-radius: 12px;
        padding: 2em;
        box-shadow: 2px 2px 6px rgba(0,0,0,0.1);
        font-size: 0.9em;
        text-align: left;
        line-height: 1.3em;
        transition: opacity 0.3s ease, transform 0.3s ease;
        animation: fadeInUp 0.25s forwards;

        p{
            color:#141417;
        }

        span{
            color: $comuna-purple;
        }
    }
}

.transition{
    transition: all 0.3s ease;
}

.tooltip{
    position: relative;

    .tooltiptext{
        position: absolute;
        left: 50%;
        color: white;
        white-space: wrap;
        background-color: $black;
        font-weight: normal;
        max-width: 324px;
        border-radius: 12px;
        padding: 8px;
        transform: translate(-50%, -50%);
        z-index: -100;
        transition: opacity 0.3s ease, top 0.3s ease;
        
        opacity: 0;
        top: -120%;

        &::after{
            content: "";
            position: absolute;
            top: 100%;
            left: 50%;
            border-width: 8px;
            border-style: solid;
            border-color: $black transparent transparent transparent;
            transform: translate(-50%, 0);
        }
    }

    &:hover .tooltiptext{
        opacity: 1;
        top: -110%;
        z-index: 100;
    }

}

.graphic{
    background-color: $comuna-green;
}

.black-text{
    color:$neutral-black;
}

.gray-text{
    color: $neutral-gray;
}

.dark-purple-text{
    color: #05007A;
}

.gray-text{
    color: $gray-text;
}

.green-text{
    color: $green-text;
}

.padding{
    padding: 42px;
}

.tabLabel{
    font-size: 1.2em;
    font-weight: bold;
    color: $gray-5;
    cursor: pointer;
}

.tabLabelSelected{
    font-size: 1.2em;
    font-weight: bold;
    color: $comuna-purple;
    cursor: pointer;
}

.proposal-input-container .react-datepicker-wrapper{
    position: absolute;
    top: 11px;
    right: 12px;
}

.border-bottom{
    border-bottom: 1px solid $gray-2;
}

.center-container{
    width: 80%;
    max-width: 1620px;
    margin:auto;
    margin-top: 49px;
}

.color-black{
    color: $black;
}

.color-green{
    color: $green;
}

.color-red{
    color: $comuna-red;
}

.benefit-option{
    background-color: #EAF7CF;
    padding: 10px;
    border-radius: 10px;
    margin-right: 10px;
    margin-top: 10px;
    border: 2px solid transparent;

    &.selected{
        background-color: #DEF2B1;
        color: #345412;
        border: 2px solid #345412;
    }

    &:hover{
        background-color: #d5f2b1;
    
    }
}

.benefit-mandatory{
    background-color: #fff;
    border: 2px solid #EFEFEE;
    padding: 10px;
    border-radius: 10px;
    margin-right: 10px;
    margin-top: 10px;
}

.bg-lime{
   background-color: #F7FCEC;
}

.bg-green{
    background-color: $green;
}

.bg-transparent {
    background-color: $comuna-transparent;
}

.bg-purple-light{
    background-color: $bg-purple-light;
}

.bg-purple-dark{
    background-color: $bg-purple-dark;
}

.hover-bg-purple-dark:hover{
    background-color: $bg-purple-dark;
}

.border-green{
    border-color:$green;
}

.border-red{
    border-color:$comuna-red;
}

.border-dash-purple{
    border: 2px dashed #CECCFF;
}

.bg-light-green{
    background-color: $ligth-green;
}

.bg-light-red{
    background-color: $comuna-red-light;
}

.color-gray-4{
    color: $gray-4;
}

.color-gray-5{
    color: $gray-5;
}

.comuna-purple{
    color: $comuna-purple;
}

.bg-comuna-green {
    background-color: $comuna-green;
}

.bg-comuna-green-light {
    background-color: $comuna-green-light;
}

.bg-comuna-red-light {
    background-color: $comuna-red-light;
}

.bg-comuna-blue-light {
    background-color: $comuna-blue-light;
}

.bg-comuna-yellow-light {
    background-color: $comuna-yellow-light;
}

.bg-comuna-lime{
    background-color: $comuna-lime;
}

.bg-comuna-lime-dark{
    background-color: $comuna-lime-dark;
}

.bg-comuna-purple{
    background-color: $comuna-purple;
}

.bg-comuna-purple-light{
    background-color: $comuna-purple-light;
}

.bg-comuna-purple-tab-light {
    background-color: $comuna-purple-tab-light;
}

.bg-comuna-purple-light-alpha {
    background-color: $comuna-purple-light-alpha;
}

.bg-comuna-purple-transparent{
    background-color: $comuna-purple-bg;
}

.bg-comuna-orange-lighter {
    background-color: $comuna-orange-lighter;
}

.bg-comuna-orange-dark {
    background-color: $comuna-orange-dark;
}

.border-comuna-purple{
    border-color: $comuna-purple;
}

.bg-screening{
    background-color: #AED1F4;
    color: #0C325A;
}

.bg-cultural{
    background-color: #FFF999 ;
    color: #5C5600;
}

.bg-technical{
    background-color: #FFE0A9;
    color: #5C3B00;
}

.bg-partner{
    background-color: #E9D7FF;
    color: #390080;
}

.bg-decision{
    background-color: #B0E8CF;
    color: #07754D;
}

.bg-comuna-cloud{
    background-color: $comuna-clouds;
}

.bg-comuna-light{
    background-color: $comuna-purple-light-bg;
}

.bg-gray-0{
    background-color: $gray-0;
}

.border-gray-0{
    border-color: $gray-0;
    border-width: 1px;
}

.bg-gray-1{
    background-color: $gray-1;
}

.bg-gray-2{
    background-color: $gray-2;
}

.bg-gray-3{
    background-color: $gray-3;
}

.bg-gray-5{
    background-color: $gray-5;
}

.border-gray-1{
    border-color: $gray-1;
}

.border-gray-2{
    border-color: $gray-2;
}

.border-gray-3{
    border-color: $gray-3;
}

.border-gray-4{
    border-color: $gray-4;
}

.error-msg{
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: left;
    background-color: #FDE7EA;
    color: $black;
    position: absolute;
    border-radius: 12px;
    line-height: 14px;
    padding: 12px;
    position: absolute;
    bottom: 60px;
    font-size: 14px;
    img{
        width: 20px;
    }
    &:before{
        content: "";
        height: 30px;
        width: 30px;
        background-color: #FDE7EA;
        position: absolute;
        bottom: -8px;
        left: 45%;
        transform: rotate(45deg);
    }
    span{
        position: relative;
    }
}

.border-1{
    border: 1px solid;
}

.table-head{
    background-color: white;
    color: $black;
    font-weight: 500;
}

.invoice-table{
    border-collapse: separate;
    border-spacing: 0 25px;
}

.preview-picture{
    height: 180px;
    width: auto;
    border-radius: 24px;
    overflow: hidden;
}

.placeholder-block{
    border-radius: 8px;
    min-height: 90px;
    width: 100%;
    border: dashed 3px $gray-2;
    margin: 12px 0px
}

.profile-placeholder{
    width: 56px;
    height: 56px;
    background: $comuna-purple-light;
    color:white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-weight: bold;
    font-size: 1.1em;
}

.profile-picture{
    width: 56px;
    height: 56px;
    border-radius: 50%;
    object-fit: cover;
}

.min-height-full{
    min-height: 100%;
}

.chat-separator{
    text-align: center;
    border-top: 2px solid #ccc;
    padding: 12px;
    border-bottom: 2px solid #ccc;
    margin-bottom: 12px;
}

.calculator-bg{
    background-color: $comuna-purple;
}

@mixin badge{
    font-size: 0.9em;
    margin-left: 5px;
    font-weight: 500;
    padding: 2px 5px;
    margin-top: 0px;
    border-radius: 5px;
    white-space: nowrap;

    &.big{
        padding: 6px 10px;
    }

    &.medium{
        padding: 4px 8px;
    }
}

.active-badge{
    background-color: #D7F4E7;
    color: #07754D;
    padding: 6px 10px;
    border-radius: 8px;
    padding-left: 27px;
    position: relative;

    &:after{
        content:"";
        width: 8px;
        height: 8px;
        background-color: #13AD13;
        border-radius: 100%;
        position: absolute;
        left: 13px;
        top: 50%;
        transform: translateY(-50%);
    }
}

.inactive-badge{
    background-color: $bg-purple-dark;
    color: $comuna-purple;
    padding: 6px 10px;
    border-radius: 8px;
    position: relative;
}

.yellow-badge{
    @include badge;
    background-color: #FFFBC2;
    color: #5C5600;
}

.yellow-badge-colors{
    background-color: #FFFBC2;
    color: #5C5600;
}

.blue-badge{
    @include badge;
    background-color: #D2E5F9;
    color: #0C325A;
}

.pink-badge {
    @include badge;
    background-color: $comuna-pink;
    color: $comuna-red-dark;
}

.benefit-badge{
    background-color: #EAF7CF;
    color:#345412;
    padding: 8px 12px;
    border-radius: 10px;
    white-space: nowrap;
    margin-bottom: 10px;
}

.mint-badge {
    @include badge;
    background-color: #EFFAF5;
    color:#07754D;
}

.red-badge{
    @include badge;
    background-color: #FCCFD5;
    color: #5F0711;
}

.purple-badge{
    @include badge;
    background-color: #F3EAFF;
    color: #390080;
}

.purple-badge-colors{
    background-color: #F3EAFF;
    color: #390080;
}

.green-badge{
    @include badge;
    background-color: #EAF7CF;
    color: #345412;
}

.gray-badge{
    @include badge;
    background-color: #EFEFEE;
    color: #141417;
    font-weight: 600;
    padding: 6px 10px;
}

.skill-badge{
    background-color: #E6E5FF;
    color:#05007A;
    padding: 6px 10px;
    border-radius: 10px;
}

.label-badge{
    background-color: $gray-1;
    color: $neutral-gray;
    padding: 6px 10px;
    border-radius: 10px;
}

.orange-badge{
    @include badge;
    background-color: #FFEDD1;
    color: #5C3B00;
}

.active-badge{
    background-color: #D7F4E7;
    color: #07754D;
    font-weight: 500;
    padding: 6px 14px;
    border-radius: 5px;
    padding-left: 26px;
    position: relative;
    &::after{
        content: "";
        width: 8px;
        height: 8px;
        background-color: #47B888;
        border-radius: 50%;
        position: absolute;
        left: 12px;
        top: 50%;
        transform: translateY(-50%);
    }
}

.w-1-7{
    width: 14.2857142857%;
}

.min-heigh-full{
    min-height: 100%;
}

.dark-placeholder-text::placeholder {
    color: $neutral-gray;
    opacity: 1;
}

.separator{
    border-top: 1px solid $gray-2;
    margin: 12px 0px;
}

.hover-right{
    transition: all 0.2s ease;
    &:hover{
        transform: translate(5px,0px);
    }
}

.hover-up{
    transition: all 0.2s ease;
    &:hover{
        transform: translate(0px,5px);
    }
}

.hover-scale{
    transition: all 0.2s ease;
    &:hover{
        transform: scale(1.05);
    }
}

.hover-bg-purple-dark:hover{
    background-color: bg-purple-dark;
}

.hover-card{
    transition: all 0.2s ease;
    &:hover{
        transform: translate(0px,-3px);
        box-shadow: 0px 10px 18px rgba(0,0,0,0.1);
    }
}

.fade-in{
    animation: fadeInUp 0.25s forwards;
}

.in-right{
    animation: in-right 1.2s forwards;
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(80px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.onboarding-v-slides>div {
    width: 100%;
    height: 100%;
    background: $comuna-purple;
    color: white;
    display: flex;
    justify-content: center;
    font-size: 3em;
    font-weight: bold;
    align-items: center;
}

.v-slides-container {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.onboarding-v-slides {
    position: relative;
    transition: all ease 0.3s;
    width: 100%;
    height: 100%;
    align-self: center;
}

.onboarding-h-slides>div {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.h-slides-container {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.onboarding-h-slides {
    position: relative;
    transition: all ease 0.3s;
    width: 100%;
    height: 100%;
    align-self: center;
}

.text-slide{
    width: 100%;
    position:relative;
}

@keyframes in-right {
    from{
        transform: translateX(100%);
    }
    to{
        transform: translateX(0%);
    }
}

@keyframes background-fade {
    from{
        background-color: rgba(0,0,0,0);
    }
    to{
        background-color: rgb(0,0,0,0.5);
    }
}

@keyframes slide-right {
    from{
        transform: translateX(100%);
    }
    to{
        transform: translateX(0%);
    }
}

.arrow-right {
    width: 0;
    height: 0;
    border-top-width: 30px;
    border-top-style: solid;
    border-top-color: transparent;
    border-bottom-width: 30px;
    border-bottom-style: solid;
    border-bottom-color: transparent;
    border-left-width: 30px;
    border-left-style: solid;
    border-left-color: $bg-purple-dark;
    

    &.purplearrow {
        border-left-width: 30px;
        border-left-style: solid;
        border-left-color: $bg-purple-dark;
    }

    &.whitearrow {
        border-left-width: 30px;
        border-left-style: solid;
        border-left-color: white;
    }

    &.greyarrow {
        // border-left: 30px solid $gray-4;
        border-left-width: 30px;
        border-left-style: solid;
        border-left-color: $gray-4;
        
    }
    
    .centered-text {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
    }
}

.interview-summary{
    p, h3, div, li{
        color: $black;
        font-size: 1rem;
    }
    b, strong{
        color: $comuna-purple;
    }
    ul {
        list-style-type: disc;
        margin-left: 20px;
        padding-left: 0;     
    }
    h3{
        margin: 10px 0px 10px 0px;
        font-size: 1.15rem;
    }
}

.comuna-checkmark{
    width: 18px;
    height: 18px;
    border: 2px solid #404040;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 1.2em;
    position: absolute;
    top:15px;
    right:15px;

    &.selected::after{
        content:"";
        background-color: #404040;
        width: 8px;
        height: 8px;
        border-radius: 50%;
    }
}
